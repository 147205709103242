<template>
  <div class="font-montserrat top">
    <div class="hero-banner">
      <img src="../../assets/images/Foodhub/foodhubBanner.webp" alt="Hero Banner Image" class="hero-image" />
    </div>

    <div class="container-fluid  mt-5 p-5">
      <div v-if="loading" class="row py-5 justify-content-center">
        <fade-loader :loading="loading" :color="color"></fade-loader>
      </div>
      <div v-else class="row">
        <div class="container">
          <div v-if="islogin" class="col-md-12 text-right">
            <a href="/view-purchased-packs" class="btn oonpay-bg mb-5 text-white btn-sm">View Purchased Pack</a>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-3" v-for="item in supercategories" :key="item.id">
              <div @click="ViewPack(item.id)" class="card">
                <img 
                 v-if="item.imageUrl"
                v-lazy="
                    `${foodhubbaseUrl}/oonpayfoodhubsetup/${item.imageUrl}?cache=${uniqueParam}`
                  " class="fixed-size-image" 
                  alt="Product Image"
                   @load="onLoad" />

                <div v-if="isLoading" class="spinner">
                  <img src="@/assets/Spinner.gif" alt="Loading..." />
                </div>

                <div class="card-body text-center">
                  <h5 class="card-title">{{ item.fullName.length > 25 ? item.fullName.slice(0, 25) + '...' :
                    item.fullName }}</h5>

                  <button @click="ViewPack(item.id)" class="btn btn-primary btn-block mt-5">
                    View Pack
                  </button>
                </div>
              </div>

            </div>
            <!-- Add more cards here if needed -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-mixed-spaces-and-tabs */
import { mapGetters } from "vuex";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { ImagebaseUrl } from "../../config";

export default {
  data() {
    return {
      foodhubbaseUrl:ImagebaseUrl,
      loading: false,
      color: "#0b4369",
      islogin: localStorage.getItem("login_status"),
      uniqueParam: Date.now(),
      isLoading: true,
    };
  },

  components: {
    FadeLoader
  },

  computed: {
    ...mapGetters("foodhub", ["supercategories"]),
    ...mapGetters("dashboard", ["all_countries"])
  },

  mounted() {
    this.loading = true;
    this.$store.dispatch("foodhub/getFoodHubSuperCategories").then((res) => {
      if (res.status == 200) {
        this.loading = false;
      }
    });
  },

  methods: {
    onLoad() {
      this.isLoading = false;
    },
    ViewPack(id) {
      localStorage.setItem("categoryId", id);
      this.$store.dispatch("foodhub/SuperCatID", id);
      this.$router.push(`/foodhub-subcategory/${id}`);
    }
  }
};
</script>

<style scoped>
.fixed-size-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
 .card-img {
   width: 100%;
   height: 200px;
   /* Set the desired height */
   object-fit: cover;
   /* Maintain aspect ratio and cover the area */
 }
.hero-banner {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: auto;
  display: block;
}
.oonpay-bg {
  background: #0c4369;
}

@media screen and (max-width: 720px) {
  .hero-banner {
    position: relative;
    margin-top: 78px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .hero-image {
    width: 100%;
    height: auto;
    display: block;
  }
  .oonpay-row {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
  .top {
    margin-top: -80px;
  }
}
</style>
