<template>
  <div>
    <the-app-bar />
    <food-hub/>
    <Footer />
  </div>
</template>

<script>
import FoodHub from '../../../components/FoodHub/foodHub.vue';
import Footer from "../../../components/footer.vue";
import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
export default {
  components: { TheAppBar, Footer, FoodHub },
};
</script>

<style lang="scss" scoped></style>
